* {
  font-family: Helvetica, Arial, sans-serif;
}

html, body {
  font-size: 1rem;
  height: 100%;
  justify-content: center;

  &.light {
    background-color: #ffffff;
    color: #000000;
  }

  &.dark {
    background-color: #000000;
    color: #ffffff;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.code {
  font-family: 'Courier New', Courier, monospace;
}  

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Light mode */
@media (prefers-color-scheme: light) {
  body {
      color: #000000;
      background-color: #ffffff;
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  body {
      color: #ffffff;
      background-color: #000000;
  }
}