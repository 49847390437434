.grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
    grid-gap: 1rem;
    padding: 0.5rem 0.5rem;
}

@media screen and (max-width: 1000px) {
    .grid{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}