.not-visible{
    display: none;
}

#project-sort{
    font-size: 1rem;
    margin-bottom: 1rem;
}



