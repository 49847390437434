.navbar {
    display: flex;
    top: 0;

    &.desktop{
        padding: 1.1rem 5%;
        justify-content: space-between;
    }

    &.mobile {
        padding: 0.1rem 5%;
    }
}

/* logo desktop */
.logo-container{
    float: left;
}
.logo {
    display: flex;                                          
    font-weight: normal;
    float: left;
    font-size: 1.85rem;

    .bold{
        font-weight: bold;
    }
}
.logo-link {
    color: inherit;
    text-decoration: none;
}
.logo-graphic{
    margin: auto;
    padding-right: 0.5rem;
}
.mobile .logo-container{
    float: left;
}

/* navbar */
.nav-text{
    width: 100%;
    text-align: left;
    color: inherit;
    text-decoration: none;
    opacity: 70%;
    transition: all 0.2s ease-out;

    &:hover{
        opacity: 100%;
        transition: all 0.2s ease;
    }
}

/* desktop navbar */
.desktop nav ul {
    display: flex;
    padding: 0;
    list-style: none;

    li{
        display: inline-block;
        margin: 1.1rem 1rem;
    }

    .contact-icon {
      margin-top: 8px;
    }
}

/* mobile navbar */
.mobile nav {
    padding: 0;
    z-index: 1;

    ul{
        padding: 0;
        margin: 0;
        list-style: none;

        a:nth-child(1) li{
            animation-delay: 0s;
        }

        a:nth-child(2) li{
            animation-delay: 0.08s;
        }

        a:nth-child(3) li{
            animation-delay: 0.13s;
        }

        span a img {
          margin-top: 16px;
          animation-delay: 0.21s;
        }
    }

    li {
        cursor: pointer;
        display: flex;
        padding: 0.75rem;
        border-bottom: 0.06rem solid #777777;
        opacity: 0;
        -webkit-animation: fadein 0.25s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.25s; /* Firefox < 16 */
        -ms-animation: fadein 0.25s; /* Internet Explorer */
        -o-animation: fadein 0.25s; /* Opera < 12.1 */
        animation: fadein 0.25s;
        animation-fill-mode: forwards;
    }

}

.mainnav{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* hamburger menu */
.hamburger-menu-container{
    margin: auto;
    width: 100%;
    float: right;
    text-align: right;
}

.mobile-nav-close{
    display: none;
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 1.1rem 5% 0 5%;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
    }

    .logo-container{
        text-align: center;
    }

    nav ul {
        text-align: center;
    }

    .contact-icon {
      padding-top: 16px;
    }
}

@media (prefers-color-scheme: light) {
  .contact-icon {
    filter: invert(0%);
    -webkit-filter: invert(0%);
  }
}

@media (prefers-color-scheme: dark) {
  .contact-icon {
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }
}