.container {
    display: block;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2.5%;
}

.container-header h1{
    font-size: 3rem;
}

.container-header h2{
    font-size: 1.75rem;
}

@media screen and (max-width: 768px) {
    .container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .container-header h1{
        font-size: 2.5rem;
    }
    
    .container-header h2{
        font-size: 1.5rem;
    }
}