.hamburger{
    cursor: pointer;
}

@media (prefers-color-scheme: light) {
    .hamburger{
        filter: invert(0%);
        -webkit-filter: invert(0%);
    }
}

@media (prefers-color-scheme: dark){
    .hamburger{
        filter:  invert(100%);
        -webkit-filter: invert(100%);
    }
}