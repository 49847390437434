.header-text{
  text-align: center;

  h1{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  h2{
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }

}