@media (prefers-color-scheme: light) {
    .dropdown-menu{
        color:#000000;
        border-color: #000000;
        background: url(./arrow-black.png) 95% / 7% no-repeat ;
    }
}
  
@media (prefers-color-scheme: dark) {
    .dropdown-menu{
        color:#FFFFFF;
        background: url(./arrow-white.png) 95% / 7% no-repeat ;
    }
}

.dropdown-label{
    font-size: 1rem;
    font-weight: bold;
}

.dropdown-menu{
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    display:inline-block;
    padding: 0.35em 1.2em;
    margin: .25em 1em;
    border-radius: 2em;
    font-weight: normal;
    text-align:center;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}