.modal-content{
    background-color: #000000;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 2.5%;

    h1{
        font-size: 2.5rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .text {
        color: black;
        background-color: inherit;
    }
}

.modal-thumbnail{
    width: 100%;
    height: auto;

    img{
        width: 100%;
    }
}

.modal-status{
    font-size: 1.25rem;
    line-height: 1.5;
}

.modal-text {
    padding-top: 1.2rem;
    line-height: 1.3;
    font-size: 1.3rem;
}

.modal-buttons {
    padding-bottom: 1rem;
}

/* Light mode */
@media (prefers-color-scheme: light) {
    .modal-container {
        color: #000000;
        background-color: #ffffff;
    }

    .modal-content {
        color: #000000;
        background-color: #ffffff;
    }
  }
  
/* Dark mode */
@media (prefers-color-scheme: dark) {
    .modal-container {
        color: #ffffff;
        background-color: #000000;
    }

    .modal-content .text {
        color: white;
        background-color: inherit;
    }
}

@media screen and (max-width: 768px) {
    .modal-buttons{
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
  .modal-thumbnail img {
    display: flex;
    margin: auto;
    width: 512px;
  }
}