.sitemap {
    list-style-type: "- /";
    font-size: 1.25rem;
    text-decoration: dotted;

    ul{
        padding-left: 1.5rem;
    }

    .list, .projects {
        list-style-type: "- /";
    }
}



